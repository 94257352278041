<template>
  <!-- Page Content  -->
  <b-container fluid>
    <b-row>
      <!-- BEGIN:Body -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Laporan Kerjasama</h4>
          </template>
          <template v-slot:body>
            <div class="align-items-center">
              <!-- BEGIN:Petunjuk -->
              <iq-card
                class="
                  iq-card-block iq-bg-info
                  text-dark
                  iq-card-stretch iq-card-height
                  p-3
                "
              >
                <div>
                  <span class="font-weight-bold"
                    >PETUNJUK PENGGUNAAN FASILITAS LAPORAN KERJASAMA :</span
                  >
                  <b-row>
                    <b-col>
                      <div>
                        1. Untuk mencari data laporan kerjasama
                        <b>per UPBJJ semua tahun</b> :
                        <br />
                        <p class="px-3">
                          - Pilih "UPBJJ" <br />
                          - Klik checkbox "Semua Tahun" <br />
                          - Klik tombol "Cari" Untuk melihat data laporan
                          kerjasama
                        </p>
                      </div>
                      <div>
                        2. Untuk mencari data laporan kerjasama
                        <b>per UPBJJ per tahun</b> :
                        <br />
                        <p class="px-3">
                          - Pilih "UPBJJ" <br />
                          - Masukan "Tahun" yang ingin dicari <br />
                          - Klik tombol "Cari" Untuk melihat data laporan
                          kerjasama
                        </p>
                      </div>
                      <div>
                        3. Untuk mencari <b>semua data laporan kerjasama</b> :
                        <br />
                        <p class="px-3">
                          - Klik checkbox "Semua Tahun" <br />
                          - Klik tombol "Cari" Untuk melihat data laporan
                          kerjasama
                        </p>
                      </div>
                    </b-col>
                    <b-col>
                      <div>
                        4. Untuk mencari data laporan kerjasama
                        <b>per jenis kerjasama semua tahun</b> :
                        <br />
                        <p class="px-3">
                          - Pilih "Jenis Kerjasama" <br />
                          - Klik checkbox "Semua Tahun" <br />
                          - Klik tombol "Cari" Untuk melihat data laporan
                          kerjasama
                        </p>
                      </div>
                      <div>
                        5. Untuk mencari data laporan kerjasama
                        <b>per jenis kerjasama per tahun</b> :
                        <br />
                        <p class="px-3">
                          - Pilih "Jenis Kerjasama" <br />
                          - Masukan "Tahun" yang ingin dicari <br />
                          - Klik tombol "Cari" Untuk melihat data laporan
                          kerjasama
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </iq-card>
              <!-- ./END:Petunjuk -->
              <!-- BEGIN:Form Pencarian -->
              <div
                class="
                  card
                  iq-card-block
                  bg-light
                  text-dark
                  iq-card-stretch iq-card-height
                "
                style="z-index: 1"
              >
                <div class="card-header bg-primary text-center">
                  <h5 class="font-weight-bold text-white">Form Pencarian</h5>
                </div>
                <div class="card-body">
                  <b-form class="px-4">
                    <div>
                      <label for="dari">Jenis Pencarian:</label
                      ><span class="text-danger ml-1">*</span>
                    </div>
                    <b-row>
                      <b-col lg="6">
                        <b-form-radio
                          inline
                          id="program1"
                          v-model="form.jenisPencarian"
                          value="0"
                          name="program"
                          >Rentang Waktu
                        </b-form-radio>
                        <b-form-radio
                          inline
                          id="program2"
                          v-model="form.jenisPencarian"
                          value="1"
                          name="program"
                          >Pilih Tahun</b-form-radio
                        >
                      </b-col>
                    </b-row>
                    <div class="mt-3"></div>
                    <b-row v-if="form.jenisPencarian == 0">
                      <b-col>
                        <b-form-group>
                          <label for="dari">Tangga Mulai:</label
                          ><span class="text-danger ml-1">*</span>
                          <flat-pickr
                            id="dari"
                            ref="refTanggalAcara"
                            class="form-control bg-white"
                            :config="basicConfig"
                            v-model="form.dari"
                            placeholder="Tanggal Mulai"
                            @input="inputTanggalPks"
                            required
                          ></flat-pickr>
                        </b-form-group>
                      </b-col>
                      <b-col class="py-5" lg="1">
                        <div class="font-weight-bold">s/d</div>
                      </b-col>
                      <b-col>
                        <b-form-group>
                          <label for="sampai">Tanggal Selesai:</label
                          ><span class="text-danger ml-1">*</span>
                          <flat-pickr
                            id="sampai"
                            ref="refTanggalAkhirAcara"
                            class="form-control bg-white"
                            :config="basicConfig"
                            v-model="form.sampai"
                            placeholder="Tanggal Selesai Kegiatan"
                            :isError="!isValidTanggalAkhir"
                            @input="inputAkhirTanggal"
                            required
                          ></flat-pickr>
                          <collapse-transition>
                            <div
                              class="text-danger mb-2"
                              v-show="isError.berlakuPks.validBerlakuPks"
                            >
                              Tanggal Selesai Kegiatan tidak bisa kurang dari
                              Tanggal Acara.
                            </div>
                          </collapse-transition>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div v-if="form.jenisPencarian == 1">Tahun:</div>
                    <b-row v-if="form.jenisPencarian == 1">
                      <b-col class="mt-1" lg="2">
                        <b-form-group>
                          <b-form-checkbox
                            id="semuaTahun"
                            v-model="form.semuaTahun"
                            required
                            @click="setSemuaTahun($event)"
                            @change="form.tahun = null"
                            :disabled="form.tahun != null"
                            >Semua (Tahun/Data)</b-form-checkbox
                          >
                        </b-form-group>
                      </b-col>
                      <b-col class="py-1" lg="1">
                        <div class="font-weight-bold">atau</div>
                      </b-col>
                      <b-col>
                        <b-form-group>
                          <input-primary
                            id="tahun"
                            v-model="form.tahun"
                            placeholder="Masukkan tahun yang ingin dicari"
                            required
                            type="number"
                            max-length="4"
                            :disabled="form.semuaTahun == 1"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-form-group>
                      <input-datalists
                        id="jenisKs"
                        class="w-full text-sm"
                        placeholder="Pilih Jenis Kerjasama"
                        v-model="form.jenisSiKs"
                        label="dataJenisKs"
                        required
                        :options="
                          (listDataKs &&
                            listDataKs.map(data => {
                              return {
                                dataJenisKs:
                                  data.kodeJenisPks + ` | ` + data.namaPks,
                                kodeJenisKs: data.kodeJenisPks,
                                idJenisKs: parseInt(data.kodeJenisPks)
                              }
                            })) ||
                            []
                        "
                      >
                        <template #header>
                          <label class="block">Jenis Kerjasama:</label>
                        </template>
                      </input-datalists>
                    </b-form-group>
                    <b-form-group>
                      <input-datalists
                        id="upbjj"
                        class="w-full text-sm"
                        placeholder="Pilih Unit"
                        v-model="form.upbjj"
                        label="dataUpbjj"
                        required
                        :options="
                          (listDataUpbjj &&
                            listDataUpbjj.map(data => {
                              return {
                                dataUpbjj: data.namaUnitPks,
                                idUnitPks: data.idUnitPks,
                                idUpbjj: parseInt(data.idUnitPks)
                              }
                            })) ||
                            []
                        "
                      >
                        <template #header>
                          <label class="block">Unit:</label>
                        </template>
                      </input-datalists>
                    </b-form-group>
                    <b-row class="mt-4" v-if="form.jenisPencarian == 0">
                      <b-col>
                        <b-btn
                          class="btn-block"
                          variant="primary"
                          @click="searchData"
                          :disabled="!isValidFormRentanWaktu"
                          >Cari Data</b-btn
                        >
                      </b-col>
                    </b-row>
                    <b-row class="mt-4" v-else-if="form.jenisPencarian == 1">
                      <b-col>
                        <b-btn
                          class="btn-block"
                          variant="primary"
                          @click="searchData"
                          :disabled="!isValidFormPilihTakun"
                          >Cari Data</b-btn
                        >
                      </b-col>
                    </b-row>
                    <b-row class="mt-4" v-else-if="form.jenisPencarian == null">
                      <b-col>
                        <b-btn
                          class="btn-block"
                          variant="primary"
                          :disabled="true"
                          >Cari Data</b-btn
                        >
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
              </div>
              <!-- ./END:Form Pencarian -->
              <!-- BEGIN:Table -->
              <div class="card mt-4">
                <div class="card-header">
                  <h5 class="font-weight-bold">Daftar Laporan Kerjasama</h5>
                  <!-- {{ form.jenisKs }} ddddd -->
                </div>
                <div class="card-body">
                  <div class="align-items-center">
                    <b-row>
                      <b-col>
                        <b-form-input
                          class="w-25"
                          id="filter-input"
                          v-model="filter"
                          type="search"
                          placeholder="Cari Data"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                    <b-table
                      responsive
                      bordered
                      :fields="thead"
                      :items="listData"
                      class="mt-2"
                      :busy="isLoading"
                      :filter="filter"
                      :per-page="perPage1"
                      :current-page="currentPage"
                      @filtered="onFiltered"
                      show-empty
                    >
                      <template v-slot:cell(keterangan)="listData">
                        <div
                          :class="listData.item.keterangan ? 'text-left' : ''"
                        >
                          {{
                            listData.item.keterangan
                              ? listData.item.keterangan
                              : '-'
                          }}
                        </div>
                      </template>

                      <template v-slot:cell(aksi)="listData">
                        <div class="d-flex">
                          <b-button
                            class="btn-block ml-2 mb-1"
                            variant="info"
                            @click="cariDetail(listData.item.kodePks)"
                            ><i class="ri-file mr-1"></i>Lihat Detail</b-button
                          >
                        </div>
                        <div
                          class="d-flex"
                          v-if="listData.item.kodeJenisPks === '001'"
                        >
                          <b-button
                            class="btn iq-bg-danger rounded-sm ml-2 mb-1"
                            variant="primary"
                            @click="
                              downloadExcelBeasiswa(
                                listData.item.kodePks,
                                listData.item.nomorPksUt
                              )
                            "
                            ><i class="ri-file mr-1"></i>Unduh Excel</b-button
                          >
                        </div>
                      </template>
                      <template #table-busy>
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Loading...</strong>
                        </div>
                      </template>
                      <template #empty>
                        <div
                          class="
                            bg-light
                            text-danger
                            font-weight-bold
                            mx-auto
                            text-center
                          "
                        >
                          Data tidak ditemukan
                        </div>
                      </template>
                    </b-table>
                    <b-row>
                      <b-col>
                        <div v-show="listData.length > 0">
                          Jumlah data: {{ totalRows }}
                        </div>
                        <b-form-group
                          label="Per page:"
                          label-for="per-page-select"
                          label-size="sm"
                          class="d-flex mt-2"
                        >
                          <b-form-select
                            class="ml-2"
                            id="per-page-select"
                            v-model="perPage1"
                            :options="pageOptions"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-pagination
                          class="float-right"
                          v-model="currentPage"
                          :total-rows="totalRows"
                          :per-page="perPage1"
                        ></b-pagination>
                      </b-col>
                    </b-row>
                    <b-row class="mt-5" v-if="!isTampilHeader == false">
                      <b-button
                        class="w-100 float-right"
                        variant=" bg-success"
                        v-b-tooltip="'Unduh Excel'"
                        @click="downloadExcelHeader()"
                        >Unduh file Excel</b-button
                      >
                    </b-row>
                  </div>
                </div>
              </div>
              <!-- ./END:Table -->
              <!-- BEGIN:Table -->
              <div class="card mt-4" v-if="!isTampil == false">
                <div class="card-header">
                  <h5 class="font-weight-bold">
                    Detail Laporan Kerjasama Kode KS
                    {{ listDataDetail[0].kodePks }}
                  </h5>
                </div>
                <div class="card-body">
                  <div class="align-items-center">
                    <b-row>
                      <b-col>
                        <b-form-input
                          class="w-25"
                          id="filter-input"
                          v-model="filter2"
                          type="search"
                          placeholder="Cari Data"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                    <b-table
                      responsive
                      bordered
                      :fields="theadDetail"
                      :items="listDataDetail"
                      class="mt-2"
                      :busy="isLoading2"
                      :filter="filter2"
                      :per-page="perPage2"
                      :current-page="currentPage2"
                      @filtered="onFiltered2"
                      show-empty
                    >
                      <template #table-busy>
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Loading...</strong>
                        </div>
                      </template>
                      <template #empty>
                        <div
                          class="
                            bg-light
                            text-danger
                            font-weight-bold
                            mx-auto
                            text-center
                          "
                        >
                          Data tidak ditemukan
                        </div>
                      </template>
                    </b-table>
                    <b-row>
                      <b-col>
                        <div v-show="listData.length > 0">
                          Jumlah data: {{ totalRows2 }}
                        </div>
                        <b-form-group
                          label="Per page:"
                          label-for="per-page-select"
                          label-size="sm"
                          class="d-flex mt-2"
                        >
                          <b-form-select
                            class="ml-2"
                            id="per-page-select"
                            v-model="perPage2"
                            :options="pageOptions2"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-pagination
                          class="float-right"
                          v-model="currentPage2"
                          :total-rows="totalRows2"
                          :per-page="perPage2"
                        ></b-pagination>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
              <!-- ./END:Table -->
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Body -->
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
// import { Vue } from 'vue-property-decorator'
// import store from '@/store/index'
// import { MENU_ROLE } from '@/config/featureStatus'

export default {
  name: 'laporan.kerjasama',
  computed: {
    ...mapState('authService', ['token']),
    isValidFormRentanWaktu() {
      const status =
        (this.form.jenisPencarian && this.form.dari) !== null &&
        this.form.sampai !== ''
      return status
    },
    isValidFormPilihTakun() {
      return (
        this.form.jenisPencarian !== null &&
        (this.form.semuaTahun || this.form.tahun) !== null
      )
    }
  },
  async mounted() {
    this.innitData()
  },
  methods: {
    /**
     * Trigger pagination to update the number of buttons/pages due to filtering
     */
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onFiltered2(filteredItems2) {
      this.totalRows2 = filteredItems2.length
      this.currentPage = 1
    },
    /**
     * Set checkbox semua tahun
     */
    setSemuaTahun(event) {
      event.target.checked === true
        ? (this.form.semuaTahun = 1)
        : (this.form.semuaTahun = 0)
    },
    isValidTanggalAkhir() {
      const { validBerlakuPks } = this.isError.berlakuPks
      return !validBerlakuPks
    },

    inputTanggalPks() {
      this.form.sampai = null
    },
    inputAkhirTanggal() {
      // -- Input Tanggal --
      // const berlakuPks = moment(this.form.berlakuPks).format('DD-MM-YYYY')
      const inputBerlakuPks = new Date(this.form.sampai)
      // -- Tanggal PKS --
      // const tglPks = moment(this.form.tglPks).format('DD-MM-YYYY')
      const inputTglPks = new Date(this.form.dari)
      if (
        inputBerlakuPks.setHours(0, 0, 0, 0) < inputTglPks.setHours(0, 0, 0, 0)
      ) {
        this.form.sampai = ''
        this.isError.berlakuPks.validBerlakuPks = true
      } else this.isError.berlakuPks.validBerlakuPks = false
    },
    /**
     * Get data Jenis KS
     */
    async getDataJenisKs_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getListMasterPks?type=jenisPks`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataKs = res.data
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Get data UPBJJ
     */
    async getDataUpbjj_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/dropdownLaporan?type=upbjj`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataUpbjj = res.data
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Search Data
     */
    async searchData() {
      this.isLoading = true
      this.allTahun = this.form.semuaTahun === true ? 1 : 0
      this.jenisKs = this.form.jenisSiKs?.kodeJenisKs
        ? this.form.jenisSiKs.kodeJenisKs
        : ''
      this.upbjj = this.form.upbjj?.idUnitPks ? this.form.upbjj.idUnitPks : ''
      this.dari = this.form.dari
      this.sampai = this.form.sampai
      this.filterWaktu = this.form.jenisPencarian
      console.log('ffff', this.form.jenisSiKs)
      if (this.filterWaktu === '0') {
        const result = await axios({
          url: `${process.env.VUE_APP_URL_PUBLIC}/getDataLaporanPks?type=jenisPks&tahun=&jenisPks=${this.jenisKs}&all=&upbjj=${this.upbjj}&dari=${this.dari}&sampai=${this.sampai}&filterWaktu=${this.filterWaktu}`,
          method: 'GET',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + this.token
          }
        })
          .then(res => {
            this.listData = res.data
            /**
             * Set the initial number of items
             */
            this.totalRows = this.listData.length
          })
          .catch(err => {
            console.log('ERROR', err)
            this.isTampilHeader = false
            throw new Error(err)
          })
        this.isLoading = false
        this.isTampilHeader = true
        return result
      } else {
        const result = await axios({
          url: `${process.env.VUE_APP_URL_PUBLIC}/getDataLaporanPks?type=jenisPks&tahun=${this.form.tahun}&jenisPks=${this.jenisKs}&all=${this.allTahun}&upbjj=${this.upbjj}&dari=&sampai=&filterWaktu=${this.filterWaktu}`,
          method: 'GET',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + this.token
          }
        })
          .then(res => {
            this.listData = res.data
            /**
             * Set the initial number of items
             */
            this.totalRows = this.listData.length
          })
          .catch(err => {
            console.log('ERROR', err)
            this.isTampilHeader = false
            throw new Error(err)
          })
        this.isLoading = false
        this.isTampilHeader = true
        return result
      }
    },

    async downloadExcelHeader() {
      this.allTahun = this.form.semuaTahun === true ? 1 : 0
      this.jenisKs = this.form.jenisSiKs?.kodeJenisKs
        ? this.form.jenisSiKs.kodeJenisKs
        : ''
      this.upbjj = this.form.upbjj?.idUnitPks ? this.form.upbjj.idUnitPks : ''
      this.dari = this.form.dari
      this.sampai = this.form.sampai
      this.filterWaktu = this.form.jenisPencarian
      console.log(this.jenisKs, 'semua')
      const result = await axios({
        // url: `${process.env.VUE_APP_URL_PUBLIC}/exportExcelKerjasama?type=jenisPks&tahun=${this.tahun}&jenisPks=${this.jenisKs}&upbjj=${this.upbjj}&all=${this.allTahun}`,
        url: `${process.env.VUE_APP_URL_PUBLIC}/exportExcelKerjasamaGeneral?type=jenisPks&tahun=${this.form.tahun}&jenisPks=${this.jenisKs}&all=${this.allTahun}&upbjj=${this.upbjj}&dari=${this.dari}&sampai=${this.sampai}&filterWaktu=${this.filterWaktu}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          var url = window.URL.createObjectURL(res.data)
          var a = document.createElement('a')
          a.href = url
          a.download = `Daftar Laporan Kerjasama-${this.jenisKs}`
          a.click()
          a.remove()
          setTimeout(() => window.URL.revokeObjectURL(url), 100)
        })
        .catch(err => {
          console.log('ERROR', err)
          this.$toast.error('File tidak ditemukan')
          throw new Error(err)
        })
      return result
    },

    async downloadExcelBeasiswa(kodeKs, nomorPksUt) {
      console.log(kodeKs, 'semua', nomorPksUt)
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/exportExcelKerjasamaBeasiswa?type=jenisPks&kodePks=${kodeKs}&NomorPksUt=${nomorPksUt}&jenisPks=001`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          var url = window.URL.createObjectURL(res.data)
          var a = document.createElement('a')
          a.href = url
          a.download = `Daftar Laporan Kerjasama Beasiswa-${kodeKs}.xlsx`
          a.click()
          a.remove()
          setTimeout(() => window.URL.revokeObjectURL(url), 100)
          this.$toast.success('File berhasil diunduh')
        })
        .catch(err => {
          console.log('ERROR', err)
          this.$toast.error('File tidak ditemukan')
          throw new Error(err)
        })
      return result
    },

    async cariDetail(kodeKs) {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getDataRekapDetailPks?type=jenisPks&kodePks=${kodeKs}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataDetail = res.data.data

          this.totalRows2 = this.listDataDetail.length
        })
        .catch(err => {
          this.isTampil = false
          this.$toast.error('Data tidak ditemukan')
          throw new Error(err)
        })
      // this.listDataDetail = ''
      this.isTampil = true
      return result
    },

    async downloadExcel(kodeKs) {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/exportExcRekapDetailBeasiswa?kodePks=${kodeKs}`,
        method: 'GET',
        responseType: 'blob',
        // data: { idIa: idIa },
        headers: {
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          var url = window.URL.createObjectURL(res.data)
          var a = document.createElement('a')
          a.href = url
          a.download = `laporan Kerjasama_${kodeKs}`
          a.click()
          a.remove()
          setTimeout(() => window.URL.revokeObjectURL(url), 100)
        })
        .catch(err => {
          console.log('ERROR', err)
          this.$toast.error('File tidak ditemukan')
          throw new Error(err)
        })
      return result
    },
    /**
     * Init Data
     */
    async innitData() {
      /**
       * Get List Data
       */
      await Promise.all([this.getDataJenisKs_()], [this.getDataUpbjj_()])
    }
  },
  data() {
    return {
      // Tabel Options
      isTampilHeader: false,
      isTampil: false,
      isLoading: false,
      isLoading2: false,
      filter: null,
      filter2: null,
      perPage1: 5,
      perPage2: 5,
      pageOptions: [5, 10, 15, { value: 100, text: '100' }],
      pageOptions2: [5, 10, 15, { value: 100, text: '100' }],
      currentPage: 1,
      currentPage2: 1,
      totalRows: 1,
      totalRows2: 1,
      // Form
      form: {
        semuaTahun: null,
        tahun: null,
        jenisSiKs: '',
        upbjj: '',
        jenisPencarian: null,
        dari: null,
        sampai: ''
      },
      // Data Jenis KS
      listDataKs: [],
      // Data UPBJJ
      listDataUpbjj: [],

      isError: {
        berlakuPks: { validBerlakuPks: false },
        berakhirPks: {
          validBerakhirPksFromTanggalKs: false
        }
      },
      // Date Picker
      basicConfig: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d'
      },
      // Table
      thead: [
        {
          label: 'Kode KS',
          key: 'kodePks',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Kode Jenis KS',
          key: 'kodeJenisPks',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Nomor PKS UT',
          key: 'nomorPksUt',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Keterangan',
          key: 'keterangan',
          class: 'text-center w-75'
        },
        { label: 'Aksi', key: 'aksi', class: 'text-center' }
      ],
      theadDetail: [
        {
          label: 'No.',
          key: 'no',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Masa',
          key: 'masa',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'nim',
          key: 'nim',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Nama Mahasiswa',
          key: 'namaMahasiswa',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Kode Beasiswa',
          key: 'kodeBeasiswa',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Ket. Beasiswa',
          key: 'keteranganBeasiswa',
          class: 'text-center',
          sortable: true
        }
      ],
      listData: [],
      listDataDetail: []
    }
  },
  beforeRouteEnter(to, from, next) {
    return next()
    // const idUnitKs = store.state.authService.idUnitKs
    // if (MENU_ROLE.DAAK_DAN_KEUANGAN.includes(String(idUnitKs))) {
    //   return next()
    // } else {
    //   Vue.$toast.error('Anda tidak memiliki hak akses')
    //   return next({ name: 'sidebar.dashboard' })
    // }
  }
}
</script>
